import * as React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import SSRProvider from 'react-bootstrap/SSRProvider'

import AdmissionsOverviewSideMenu from "../../../../../components/admissionsOverviewMenu"
import BasicLearnMorePanel from "../../../../../components/basicLearnMorePanel"
import DefaultHeader from "../../../../../components/default-header"
import Footer from "../../../../../components/footer"
import GdprPanel from "../../../../../components/gdpr"
import Navigation from "../../../../../components/navigation"
import OnlineApplication from "../../../../../components/onlineApplication"
import Seo from "../../../../../components/seo"
import NewsletterSignup from "../../../../../components/newsletterSignUp"

const Page = ({ data }) => {

    const post = data.mdx;
    const iframeSRC = `https://meridianu.formstack.com/forms/admission_application`;

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <DefaultHeader type="xl" headline={post.frontmatter.headline} heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline} />
            <div id="body">
                <Container fluid="lg">
                    <Row>
                    <Col lg={9} md={8} className="pe-md-5 mb-md-5 mb-3">
                            <Row className="white-bg mb-5"> 
                                <Col className="p-4">
                                    <div className="apply-online">
                                        <OnlineApplication src={iframeSRC} width="100%" height="1200" />
                                    </div>
                                </Col>
                            </Row>   
                        </Col>
                        <Col lg={3} md={4} >
                            <AdmissionsOverviewSideMenu />
                            <BasicLearnMorePanel menuType="basic" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage}
    />
)

export const query = graphql`
    query OnlineApplicationPageQuery {
        mdx(fileAbsolutePath: {regex: "/online-application.mdx/"})  {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }    
`

export default Page