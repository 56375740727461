import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './styles/basicLearnMorePanel.scss';



const BasicLearnMorePanel = ({ program, menuType }) => {


        if (menuType === "basic") {
            return (
                <div id="learnMorePanel">
                    <Row className="my-3 my-lg-5 white-bg">
                        <Col xs={12} className="p-3 p-lg-4">
                            <h3 className="info-title">Learn More</h3>
                            <p className="info-text">Interested in learning more about the programs at Meridian?</p>
                            <Button className="btn-fw" variant="primary" href="mailto:admissions@meridianuniversity.edu">email an advisor »</Button>
                            <Button className="btn-fw" variant="primary" href="/admissions/graduate-admissions/schedule-with-admissions/">schedule a call »</Button>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (
                <div id="learnMorePanel">
                    <Row className="my-3 my-lg-5 white-bg">
                        <Col xs={12} className="p-3 p-lg-4">
                            <h3 className="info-title">Learn More</h3>
                            <p className="info-text">Interested in learning more about the { program } program at Meridian?</p>
                            <Button className="btn-fw" variant="primary" href="mailto:admissions@meridianuniversity.edu">email an advisor »</Button>
                            <Button className="btn-fw" variant="primary" href="/admissions/graduate-admissions/schedule-with-admissions/">schedule a call »</Button>
                        </Col>
                    </Row>
                </div>
            )
        }
}

export default BasicLearnMorePanel